var main = require('integrations/main');

Object.assign(main.baseFiles, {
    cart: require('client_core/cart'),
    footer: require('client_core/components/footer'),
    search: require('client_core/components/search').init,
    countrySelector: require('client_core/components/countrySelector')
});

module.exports = main;
